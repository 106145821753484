import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Constants from './constants'
import * as Cookies from './cookies-module'
import * as Session from './session'

export function useReturningPlayerListener() {
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )
  const [returningPlayer, setReturningPLayer] = Cookies.useCookie(
    Constants.CookieKeys.RETURNING_PLAYER
  )

  React.useEffect(() => {
    if (authenticated && !returningPlayer) {
      setReturningPLayer(true, {
        httpOnly: false,
        maxAge: 365 * 24 * 60 * 60,
        path: '/',
      })
    }
  }, [authenticated, returningPlayer])
}
