import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'
import { Provider, createClient, dedupExchange, fetchExchange } from 'urql'

import { cacheExchange } from '@urql/exchange-graphcache'
import { devtoolsExchange } from '@urql/devtools'
import { suspenseExchange } from '@urql/exchange-suspense'

import * as Agent from './agent'
import * as ClientConfig from './client-configuration'
import * as Constants from './constants'
import * as Cookies from './cookies-module'
import * as Http from './http-module'
import { loggerExchange } from './logger-exchange'

function applyWhen(cond, exchange) {
  if (cond()) {
    return exchange
  }

  return ({ forward }) => forward
}

export function GraphqlProvider(props) {
  const acceptLanguage = Http.useRequestHeader('Accept-Language')
  const locale = Http.useLocale()
  const clientType = ClientConfig.useClientType()
  const [countryCode] = ClientConfig.useCountryCode()
  const [language] = ClientConfig.useLanguage()
  const url = ClientConfig.useGraphqlUrl()

  const [token] = Cookies.useCookie(Constants.CookieKeys.TOKEN)
  const [abUserId] = Cookies.useCookie(Constants.CookieKeys.AB_USER_ID)

  const agent = React.useMemo(() => Agent.getAgentByUrl(url), [url])

  const client = React.useMemo(
    () =>
      createClient({
        exchanges: [
          applyWhen(
            () => process.env.NODE_ENV !== 'production',
            devtoolsExchange
          ),
          loggerExchange,
          dedupExchange,
          applyWhen(() => Boolean(process.browser), suspenseExchange),
          props.ssrCache,
          cacheExchange({
            keys: {
              Catalog: data => data.kind,
              Configuration: data => data.brand,
              Country: data => data.alpha2,
              Currency: data => data.code,
              Department: data => data.primary,
              Locale: data => data.slug,
              Moment: data => `${data.gameId}-${data.settleTimestamp}`,
              Provider: data => data.name,
              SeoMetadata: data => data.url,
            },
          }),
          fetchExchange,
        ],
        fetchOptions: {
          agent,
          headers: R.reject(R.isNil, {
            'Accept-Language': acceptLanguage,
            Authorization: token,
            'Frontend-Ab-User-Id': abUserId,
            'Frontend-Client-Type': clientType,
            'Frontend-Country-Code': locale.region || countryCode,
            'Frontend-Language': locale.language || language,
          }),
        },
        // We ran into issue with thrown promises not being caught by React
        // Reconciler, which caused app crash/
        // TODO: Enable client-side Suspense when possible
        suspense: !process.browser,
        url,
      }),
    [
      abUserId,
      acceptLanguage,
      agent,
      clientType,
      countryCode,
      language,
      locale.language,
      locale.region,
      props.ssrCache,
      token,
      url,
    ]
  )

  return <Provider value={client}>{props.children}</Provider>
}

GraphqlProvider.propTypes = {
  children: PropTypes.element,
  ssrCache: PropTypes.func,
  url: PropTypes.string,
}
