import * as FramerMotion from 'framer-motion'
import * as ReduxEffects from 'redux-effects'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Forms from '@rushplay/forms'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constants from './constants'
import * as Theming from './theming'
import * as Cookies from './cookies'
import * as Icons from './icons'
import { Button } from './button'
import { CookieConsentCheckboxGroup } from './cookie-consent-checkbox-group'
import { FieldBuilder } from './field-builder'
import { HtmlContent } from './html-content'
import { SubmitButton } from './submit-button'
import { consentSchema } from './cookie-consent-ui-schema'

const dataSchema = {
  type: 'object',
  properties: {
    consent: {
      type: 'array',
      items: {
        type: 'string',
        enum: ['base', 'ads'],
      },
    },
  },
  required: ['consent'],
}

function Submit() {
  const i18n = I18n.useI18n()
  return (
    <Common.Box textAlign="center" mx="auto" pb={2}>
      <SubmitButton>{i18n.translate('cookie.submit')}</SubmitButton>
    </Common.Box>
  )
}

const CloseWrapper = styled.div`
  ${css({
    px: 0,
    marginTop: '-10px',
    color: 'g-text',
    fontSize: 3,
    '&:hover, &:active': {
      color: 'inputHover',
    },
  })}
  display: flex;
  justify-content: flex-end;
`

export function CookieConsentBanner() {
  const [visible, setVisible] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)

  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    dispatch(
      ReduxEffects.bind(
        Cookies.get(Constants.CookieKeys.COOKIE_CONSENT),
        value => {
          setVisible(!value)
        }
      )
    )
  }, [])

  function handleSubmit(errors, data) {
    // There isn't real user input so we skip error handler
    const cookieConsent = data ? data.consent : ['base', 'ads']
    setVisible(false)
    dispatch(
      Cookies.set('cookie-consent', cookieConsent, {
        // Consent must be given at minimum every year, hence the 1 year cookie age
        maxAge: 365 * 24 * 60 * 60 * 1000,
        httpOnly: false,
        path: '/',
      })
    )
  }
  return (
    <Theming.Alternative>
      <FramerMotion.AnimatePresence>
        {visible && (
          <FramerMotion.motion.div
            key="cookie-banner"
            animate={{ opacity: 1, y: 0 }}
            exit={{
              opacity: 0,
              y: 100,
              transition: {
                duration: 0.2,
              },
            }}
            initial={{
              opacity: 0,
              y: 100,
              position: 'fixed',
              bottom: '0px',
              left: '0px',
              right: '0px',
              zIndex: '9999',
            }}
            transition={{
              duration: 0.3,
              delay: 0.5,
              type: 'spring',
              stiffness: 140,
              damping: 20,
            }}
          >
            {isOpen && (
              <Common.Box
                display="flex"
                backgroundColor="cookie-banner-background"
                boxShadow={0}
                color="g-text"
                mb="1"
                pt="1"
                borderRadius={0}
                maxWidth={[null, '500px']}
                flexDirection="column"
              >
                <CloseWrapper onClick={() => setIsOpen(!isOpen)}>
                  <Icons.Clear />
                </CloseWrapper>

                <Forms.Provider
                  schema={dataSchema}
                  name="consent"
                  onSubmit={(errors, data) => handleSubmit(errors, data)}
                >
                  <FieldBuilder
                    uiSchema={consentSchema}
                    initialValues={{
                      '#/properties/consent': ['base', 'ads'],
                    }}
                    components={{
                      multipleSelectField: CookieConsentCheckboxGroup,
                      submit: Submit,
                    }}
                  />
                </Forms.Provider>
              </Common.Box>
            )}

            <Common.Box
              width="100%"
              backgroundColor="cookie-banner-background"
              color="g-text"
              boxShadow={0}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderTop="1px solid #efefef"
              py={0}
              px={1}
              fontSize={[0, 1]}
            >
              <HtmlContent
                html={{ __html: i18n.translate('cookie-disclaimer') }}
              />
              <Common.Box
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Common.Box
                  cursor="pointer"
                  onClick={() => setIsOpen(!isOpen)}
                  pl={0}
                >
                  {i18n.translate('cookie.settings')}
                </Common.Box>
                <Common.Space pl={0}>
                  <Button fontSize={1} onClick={handleSubmit}>
                    {i18n.translate('cookie.accept')}
                  </Button>
                </Common.Space>
              </Common.Box>
            </Common.Box>
          </FramerMotion.motion.div>
        )}
      </FramerMotion.AnimatePresence>
    </Theming.Alternative>
  )
}
