import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import * as forms from '@rushplay/forms'
import { lazy as loadable } from '@loadable/component'
import { useI18n } from '@rushplay/i18n'

import * as Constants from './constants'
import { FieldLabel } from './field-label'
import { Input } from './input'
import { getFieldNormalizer } from './get-field-normalizer'
import { getFieldParser } from './get-field-parser'

const FieldResponse = loadable(() => import('./field-response'))

export function InputField(props) {
  const i18n = useI18n()
  const form = forms.useFormContext()
  const field = forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })

  return (
    <common.Flex flexDirection="column">
      {i18n.hasTranslation(field.label) && (
        <FieldLabel disabled={props.disabled} htmlFor={field.name}>
          {i18n.translate(field.label)}
        </FieldLabel>
      )}
      <Input
        visited={
          !R.includes(field.status, [
            forms.FieldStatus.PRISTINE,
            forms.FieldStatus.ABSENT,
          ])
        }
        valid={R.isEmpty(field.errors)}
        autoComplete={props.autoComplete}
        autoCorrect={props.autoCorrect}
        autoFocus={props.autoFocus}
        currency={props.currency}
        disabled={props.disabled}
        inputMode={props.inputMode}
        maxLength={props.maxLength}
        minLength={props.minLength}
        name={field.name}
        id={`${form.name}-${field.name}`}
        prependIcon={props.prependIcon}
        placeholder={
          i18n.hasTranslation(field.placeholder)
            ? i18n.translate(field.placeholder)
            : ''
        }
        type={props.type}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
      />
      <FieldResponse scope={props.scope} />
    </common.Flex>
  )
}

InputField.defaultProps = {
  disabled: false,
  type: 'text',
  normalize: value => value || undefined,
}

InputField.propTypes = {
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  currency: PropTypes.oneOf(Constants.CurrencyCodes),
  disabled: PropTypes.bool,
  format: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputMode: PropTypes.string,
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  placeholder: PropTypes.string,
  prependIcon: PropTypes.element,
  scope: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password']),
}
