import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Forms from '@rushplay/forms'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import { lazy as loadable } from '@loadable/component'

import { Checkbox } from './checkbox'
import { getFieldNormalizer } from './get-field-normalizer'
import { getFieldParser } from './get-field-parser'

const FieldResponse = loadable(() => import('./field-response'))

export function CookieConsentCheckboxGroup(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })

  function handleChange(key, value) {
    // checkbox was false
    if (value && R.includes(key, field.value)) {
      return field.onChangeValue(R.without(key, field.value))
    }
    // checkbox was true
    return field.onChangeValue(R.append(key, field.value))
  }

  if (field.status === 'ABSENT') {
    return null
  }

  return (
    <Common.Box display="flex" flexDirection="column" px="2" py="0">
      {R.map(
        item => (
          <Common.Box key={item.value} pb={1}>
            <Common.Box fontWeight="bold" fontSize={[1, 2]} pb={1}>
              {i18n.translate(`${item.label}.title`)}
            </Common.Box>
            <Common.Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Common.Box fontSize={[0, 1]} pr={3}>
                {i18n.translate(`${item.label}.description`)}
              </Common.Box>
              <Common.Box>
                <Checkbox
                  key={item.value}
                  name={item.value}
                  disabled={item.value === 'base'}
                  checked={R.includes(item.value, field.value)}
                  value={item.value}
                  onChange={value => handleChange(item.value, value)}
                />
              </Common.Box>
            </Common.Box>
          </Common.Box>
        ),
        props.options
      )}
      <FieldResponse scope={props.scope} />
    </Common.Box>
  )
}

CookieConsentCheckboxGroup.propTypes = {
  initialValue: PropTypes.array,
  format: PropTypes.string,
  label: PropTypes.string,
  normalize: PropTypes.func,
  options: PropTypes.array,
  parse: PropTypes.func,
  scope: PropTypes.string,
  value: PropTypes.string,
}
