import * as R from 'ramda'
import * as Urql from 'urql'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import kebabCase from 'lodash.kebabcase'
import { Helmet } from 'react-helmet-async'

import * as I18n from '@rushplay/i18n'

import * as Constants from './constants'
import * as ServerConfiguration from './server-configuration'

const getSeoMetadata = `
  query SeoMetadata($url: String!) {
    seoMetadata(url: $url) {
      description
      title
    }
  }
`
const getGame = `
  query GameInfo($id: ID!) {
    game(id: $id) {
      displayProvider
      id
      key
      provider
      title
    }
  }
`

function GameSeoMetadataFallback(props) {
  const i18n = I18n.useI18n()

  const [gameResponse] = Urql.useQuery({
    query: getGame,
    variables: { id: props.gameId },
  })
  const { brand } = ServerConfiguration.useContext()
  const game = R.pathOr({}, ['game'], gameResponse.data)
  const provider = game.displayProvider || game.provider

  const fallbackGameTitle = `${game.title} - ${game.displayProvider ||
    game.provider} - ${Constants.BrandName[brand]}`

  const fallbackGameDescription = `game-info.${kebabCase(provider)}.${kebabCase(
    game.key
  )}.description`

  // Gets rid of html tags
  const metaDescriptionContent =
    i18n.hasTranslation(fallbackGameDescription) &&
    i18n.translate(fallbackGameDescription).replace(/<[^>]+>/g, '')

  if (gameResponse.fetching || gameResponse.error) {
    return (
      <Helmet>
        <title>{i18n.translate(`seo.start.title`)}</title>
        <meta name="title" content={i18n.translate(`seo.start.title`)} />
      </Helmet>
    )
  }

  return (
    <Helmet>
      <title>{fallbackGameTitle}</title>
      <meta name="title" content={fallbackGameTitle} />
      {metaDescriptionContent && (
        <meta name="description" content={metaDescriptionContent} />
      )}
    </Helmet>
  )
}

GameSeoMetadataFallback.propTypes = {
  gameId: PropTypes.string,
}

export function SeoMetadata() {
  const i18n = I18n.useI18n()
  const location = ReactRouter.useLocation()
  const match = ReactRouter.useRouteMatch({
    path: '/casino/games/:gameId/',
  })

  const [response] = Urql.useQuery({
    query: getSeoMetadata,
    variables: { url: location.pathname },
  })

  if (!response.fetching && match && response.data.seoMetadata == null) {
    return <GameSeoMetadataFallback gameId={match.params.gameId} />
  }

  if (
    response.fetching ||
    response.error ||
    R.isNil(response.data.seoMetadata)
  ) {
    return (
      <Helmet>
        <title>{i18n.translate(`seo.start.title`)}</title>
        <meta name="title" content={i18n.translate(`seo.start.title`)} />
        <meta name="description" content={i18n.translate(`seo.start.desc`)} />
      </Helmet>
    )
  }

  return (
    <Helmet>
      <title>
        {i18n.translate(R.path(['seoMetadata', 'title'], response.data))}
      </title>
      <meta
        name="title"
        content={i18n.translate(
          R.path(['seoMetadata', 'title'], response.data)
        )}
      />
      {R.path(['seoMetadata', 'description'], response.data) && (
        <meta
          name="description"
          content={i18n.translate(
            R.path(['seoMetadata', 'description'], response.data)
          )}
        />
      )}
    </Helmet>
  )
}
