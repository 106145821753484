import * as R from 'ramda'

import { ClientType } from '../constants'
import { toPreferredLanguages } from '../translations/accepted-languages'

const SUPPORTED_CLIENT_TYPES = R.values(ClientType)

export const INITIAL_STATE = {
  clientCountryCode: null,
  clientType: ClientType.UNKNOWN,
  devicePixelRatio: 1,
  graphqlUrl: null,
  features: null,
  origin: null,
  playerCountryCode: null,
  preferredLanguages: [],
}

const CLIENT_TYPE_UPDATED = 'CLIENT_TYPE_UPDATED'
const CLIENT_COUNTRY_CODE_UPDATED = 'CLIENT_COUNTRY_CODE_UPDATED'
const DEVICE_PIXEL_RATIO_UPDATED = 'DEVICE_PIXEL_RATIO_UPDATED'
const GRAPHQL_URL_UPDATED = 'GRAPHQL_URL_UPDATED'
const LANGUAGE_UPDATED = 'LANGUAGE_UPDATED'
const LOCALE_UPDATED = 'LOCALE_UPDATED'
const PLAYER_COUNTRY_CODE_UPDATED = 'PLAYER_COUNTRY_CODE_UPDATED'
const PREFERRED_LANGUAGES_UPDATED = 'PREFERRED_LANGUAGES_UPDATED'

export function updateClientType(clientType) {
  return {
    type: CLIENT_TYPE_UPDATED,
    payload: clientType,
  }
}

export function updateClientCountryCode(countryCode) {
  return {
    type: CLIENT_COUNTRY_CODE_UPDATED,
    payload: countryCode,
  }
}

export function updateDevicePixelRatio(devicePixelRatio) {
  return {
    type: DEVICE_PIXEL_RATIO_UPDATED,
    payload: devicePixelRatio,
  }
}

export function updateGraphqlUrl(graphqlUrl) {
  return {
    type: GRAPHQL_URL_UPDATED,
    payload: graphqlUrl,
  }
}

export function updateLanguage(language) {
  return {
    type: LANGUAGE_UPDATED,
    payload: language,
  }
}

export function updateLocale(language, region) {
  return {
    type: LOCALE_UPDATED,
    payload: { language, region },
  }
}

export function updatePlayerCountryCode(countryCode) {
  return {
    type: PLAYER_COUNTRY_CODE_UPDATED,
    payload: countryCode,
  }
}

export function updatePreferredLanguages(request) {
  return {
    type: PREFERRED_LANGUAGES_UPDATED,
    payload: toPreferredLanguages(request),
  }
}

export function reducer(state, action) {
  switch (action.type) {
    case CLIENT_TYPE_UPDATED: {
      const isSupported = R.includes(action.payload, SUPPORTED_CLIENT_TYPES)

      const nextClientType = isSupported ? action.payload : ClientType.UNKNOWN

      return R.assoc('clientType', nextClientType, state)
    }

    case CLIENT_COUNTRY_CODE_UPDATED: {
      const nextClientCountryCode = action.payload

      if (R.propEq('clientCountryCode', nextClientCountryCode, state)) {
        return state
      }

      return R.assoc('clientCountryCode', nextClientCountryCode, state)
    }

    case DEVICE_PIXEL_RATIO_UPDATED: {
      // No need to optimize for higher DPR, so cap value to 2
      const nextDevicePixelRatio = Math.min(action.payload, 2)

      return R.assoc('devicePixelRatio', nextDevicePixelRatio, state)
    }

    case GRAPHQL_URL_UPDATED: {
      if (process.browser) {
        // Determined on server; must be immutable on client
        return state
      }

      const nextGraphqlUrl = action.payload

      return R.assoc('graphqlUrl', nextGraphqlUrl, state)
    }

    case LANGUAGE_UPDATED: {
      const nextLanguage = action.payload

      return R.assoc('language', nextLanguage, state)
    }

    case LOCALE_UPDATED: {
      const nextLocale = action.payload

      return R.reduce(reducer, state, [
        updateLanguage(nextLocale.language),
        updatePlayerCountryCode(nextLocale.region),
      ])
    }

    case PLAYER_COUNTRY_CODE_UPDATED: {
      const nextPlayerCountryCode = action.payload

      return R.assoc('playerCountryCode', nextPlayerCountryCode, state)
    }

    case PREFERRED_LANGUAGES_UPDATED: {
      if (process.browser) {
        // Determined on server; must be immutable on client
        return state
      }

      const nextLanguages = action.payload

      return R.assoc('preferredLanguages', nextLanguages, state)
    }

    default: {
      return state
    }
  }
}

export function getClientType(state) {
  return R.pathOr(ClientType.UNKNOWN, ['clientType'], state)
}

export function getClientCountryCode(state) {
  return R.pathOr(null, ['clientCountryCode'], state)
}

export function getDevicePixelRatio(state) {
  return R.pathOr(1, ['devicePixelRatio'], state)
}

export function getGraphqlUrl(state) {
  return R.path(['graphqlUrl'], state)
}

export function getLanguage(state) {
  return R.pathOr(null, ['language'], state)
}

export function getPlayerCountryCode(state) {
  return R.pathOr(null, ['playerCountryCode'], state)
}

export function getPreferredLanguages(state) {
  return R.pathOr([], ['preferredLanguages'], state)
}

export function getCountryCode(state) {
  return getPlayerCountryCode(state) || getClientCountryCode(state)
}

export function getLocale(state) {
  const language = getLanguage(state)
  const region = getCountryCode(state)

  if (language && region) {
    return { language, region }
  }

  return {}
}
