import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import { createSelector } from 'reselect'

import * as Analytics from '@rushplay/analytics'
import * as casino from '@rushplay/casino'
import * as Offers from '@rushplay/offers'
import * as Triggers from '@rushplay/triggers'

import * as Configuration from './configuration'
import * as Constants from './constants'
import * as Cookies from './cookies-module'
import * as Inventory from './inventory'
import * as Player from './player'
import * as Session from './session'
import * as gameCatalog from './game-catalog'
import * as pickedGames from './picked-games'
import * as Promotions from './promotions'

function getAmountCents(_, props) {
  return props.amountCents
}

/**
 * @param {Object} state
 * @param {Object} props
 * @returns {Object} game
 */
export const getGameById = createSelector(
  [
    (state, props) => pickedGames.getGame(state.pickedGames, { id: props.id }),
    (state, props) => gameCatalog.getGame(state.gameCatalog, { id: props.id }),
    (state, props) => casino.selectors.getGame(state.casino, { id: props.id }),
  ],
  (pickedGame, catalogGame, casinoGame) => {
    const game = pickedGame || catalogGame || casinoGame || {}

    const blitzEnabled =
      R.not(R.isNil(game.blitzProperties)) ||
      R.defaultTo(false, game.blitzEnabled)

    return R.assoc('blitzEnabled', blitzEnabled, game)
  }
)

/**
 * @param {Object} state
 * @returns {Object} welcome offer
 */
export function getWelcomeOffer(state) {
  return R.head(
    Offers.getCalculatedOffers(state, {
      depositNumber: 0,
      amountCents: 0,
    })
  )
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {number} props.amountCents
 * @returns {array} of calculated offers
 */
export const getCalculatedOffers = createSelector(
  [
    state => state.offers,
    getAmountCents,
    state => Player.getDepositCount(state.player),
  ],
  (state, amountCents = 0, depositNumber = 0) =>
    Offers.getCalculatedOffers(state, { amountCents, depositNumber })
)

/**
 * @param {Object} state
 * @returns {boolean} has promotion notifications
 */
export const hasPromotionNotifications = createSelector(
  [
    state => Promotions.hasUnseenPromotions(state.promotions),
    state => Inventory.hasUnseenItems(state.inventory),
  ],
  (hasUnseenPromotions, hasUnseenInventory) =>
    R.any(item => R.equals(true, item), [
      hasUnseenPromotions,
      hasUnseenInventory,
    ])
)

/**
 * @param {Object} state
 * @returns {boolean} has any kind of notifications
 */
export const hasNotifications = createSelector(
  [hasPromotionNotifications],
  hasPromotionNotifications => hasPromotionNotifications
)
/**
 * @param {Object} state
 * @returns {string} affiliateClickId
 */
export const getAffiliateClickId = createSelector(
  [
    state => Player.getAffiliateClickId(state.player),
    state => Analytics.getClickId(state.analytics),
  ],
  (playerClickId, analyticsClickId) => playerClickId || analyticsClickId
)

/**
 * @param {Object} state
 * @returns {string} affiliateSubId
 */
export const getAffiliateSubId = createSelector(
  [
    state => Player.getAffiliateSubId(state.player),
    state => Analytics.getSubId(state.analytics),
  ],
  (playerSubId, analyticsSubId) => playerSubId || analyticsSubId
)

/**
 * @param {Object} state
 * @returns {string} affiliateWebmasterId
 */
export const getAffiliateWebmasterId = createSelector(
  [
    state => Player.getAffiliateWebmasterId(state.player),
    state => Analytics.getWebmasterId(state.analytics),
  ],
  (playerWebmasterId, analyticsWebmasterId) =>
    playerWebmasterId || analyticsWebmasterId
)

export function getWelcomeOfferId(state) {
  return R.path(['id'], getWelcomeOffer(state))
}

/**
 * Get lowest range of welcome offer (Min deposit to claim it) in cents
 * @param {*} state
 * @returns {number}
 */
export function getWelcomeOfferMinDepositLimit(state) {
  return R.path(['rangeLimits', 0], getWelcomeOffer(state))
}

/**
 * Check if Welcome Offer is claimable
 * @param {*} state
 * @param {Object} props
 * @param {number} props.amountCents
 * @returns {Boolean}
 */
export const isWelcomeOfferClaimable = createSelector(
  [getWelcomeOfferMinDepositLimit, getAmountCents],
  /* eslint-disable-next-line */
  (minDepositLimit, amountCents) => R.lte
)

/**
 * @param {*} state
 * @param {Object} props
 * @param {number} props.amountCents
 * @returns {string}
 */
export const getClaimableWelcomeOfferId = createSelector(
  [isWelcomeOfferClaimable, getWelcomeOfferId],
  (isWelcomeOfferClaimable, welcomeOfferId) => {
    return isWelcomeOfferClaimable ? welcomeOfferId : ''
  }
)

export const isInventoryStoreEnabled = createSelector(
  [
    state => Session.isAuthenticated(state.session),
    state => Player.isInventoryStoreEnabled(state.player),
    state => Configuration.isInventoryStoreEnabled(state.configuration),
  ],
  (authenticated, enabledForPlayer, enabledGlobally) =>
    // We need an additional check here because we want to rely on
    // enabledForPlayer only when we already have player data fetched
    authenticated && typeof enabledForPlayer !== 'undefined'
      ? enabledForPlayer
      : enabledGlobally
)

export function createSeenInventoryStoreSelector() {
  const getStoreTrigger = Triggers.createGetTriggerSelector()
  return createSelector(
    [
      state => Session.isAuthenticated(state.session),
      state =>
        getStoreTrigger(state.triggers, {
          kind: 'real_money_store',
          event: 'onboarding',
        }),
      state => Player.hasSeenInventoryStore(state.player),
    ],
    (authenticated, hasUserSeenStore, hasStoreSeen) =>
      authenticated ? !hasUserSeenStore : hasStoreSeen
  )
}

export function createSeenSportsSelector() {
  const getStoreTrigger = Triggers.createGetTriggerSelector()
  return createSelector(
    [
      state => Session.isAuthenticated(state.session),
      state =>
        getStoreTrigger(state.triggers, {
          kind: 'sports',
          event: 'onboarding',
        }),
      state => Player.hasSeenSports(state.player),
    ],
    (authenticated, hasUserSeenSports, hasSportsSeen) =>
      authenticated ? !hasUserSeenSports : hasSportsSeen
  )
}

export function createSeenMahjongSelector() {
  const getMahjongTrigger = Triggers.createGetTriggerSelector()
  return createSelector(
    [
      state => Session.isAuthenticated(state.session),
      state =>
        getMahjongTrigger(state.triggers, {
          kind: 'mahjong',
          event: 'onboarding',
        }),
      state => Player.hasSeenMahjong(state.player),
    ],
    (authenticated, hasUserSeenMahjong, hasMahjongSeen) => {
      return authenticated ? !hasUserSeenMahjong : hasMahjongSeen
    }
  )
}

/**
 * Check if user is authenticated
 *
 * @returns {boolean}
 */
export function useAuthentication() {
  return ReactRedux.useSelector(state => Session.isAuthenticated(state.session))
}

/**
 * Check if user needs to verify their phone number
 *
 * @returns {boolean}
 */
export function useIsPhoneVerificationRequired() {
  const isPhoneVerificationRequiredFromStore = ReactRedux.useSelector(state =>
    Player.isPhoneVerificationRequired(state.player)
  )
  const [isPhoneVerificationRequiredFromCookies] = Cookies.useCookie(
    Constants.CookieKeys.IS_PHONE_VERIFICATION_REQUIRED
  )
  return (
    (isPhoneVerificationRequiredFromCookies &&
      JSON.parse(isPhoneVerificationRequiredFromCookies)) ||
    isPhoneVerificationRequiredFromStore
  )
}
