import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as locks from '@rushplay/compliance/locks'
import * as offers from '@rushplay/offers'
import * as Triggers from '@rushplay/triggers'

import * as CombinedSelectors from './combined-selectors'
import * as player from './player'
import * as promotions from './promotions'
import * as Inventory from './inventory'
import * as session from './session'
import { analyticsRegistration } from './analytics'

/**
 * Maintain player-dependent state up-to-date
 */
export function useSessionListener() {
  const dispatch = useDispatch()
  const affiliateId = useSelector(state => player.getAffiliateId(state.player))
  const token = useSelector(state => session.getToken(state.session))
  const isPhoneVerificationRequired = CombinedSelectors.useIsPhoneVerificationRequired()

  // Fetch data on token change
  React.useEffect(() => {
    if (token) {
      if (!isPhoneVerificationRequired) {
        dispatch([
          analyticsRegistration(),
          player.fetchPlayerInfo(),
          locks.fetch(),
          offers.fetchDepositOffers({ affiliateId }),
          promotions.fetch(),
          Inventory.fetch(),
          Triggers.fetch(),
        ])
      }
    }
  }, [token, isPhoneVerificationRequired])

  React.useEffect(() => {
    function handleKeepAlive() {
      if (!document.hidden) {
        dispatch(session.keepAlive(token))
      }
    }

    if (token) {
      // Setup listener for tab-change and pauses. (example: closing lid of laptop)
      document.addEventListener('visibilitychange', handleKeepAlive)

      return () => {
        document.removeEventListener('visibilitychange', handleKeepAlive)
      }
    }
  }, [token])
}
