import * as ReactRedux from 'react-redux'
import React from 'react'

import * as Utils from './utils'
import * as Configuration from './configuration'
import { useBrand } from './use-brand'

export function createBrandedComponent(name, components) {
  const Switchable = Utils.Components.createSwitchableComponent(
    'brand',
    components
  )

  function BrandedComponent(props) {
    const brand = useBrand()
    const subBrand = ReactRedux.useSelector(state =>
      Configuration.getSubBrand(state.configuration)
    )

    return <Switchable {...props} brand={subBrand || brand} />
  }

  BrandedComponent.displayName = `Branded(${name})`

  return BrandedComponent
}
