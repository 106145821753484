import { lazy as loadable } from '@loadable/component'

import { createBrandedComponent } from '../create-branded-component'

const BRANDED_COMPONENTS = {
  playboom: loadable(() => import('./fonts-boom')),
  playboom24: loadable(() => import('./fonts-boom')),
  freedomcasino: loadable(() => import('./fonts-boom')),
  wettenlive: loadable(() => import('./fonts-boom')),
  casinosky: loadable(() => import('./fonts-boom')),
  casinoskyjp: loadable(() => import('./fonts-boom')),
  paradiseplay: loadable(() => import('./fonts-boom')),
}

export const Fonts = createBrandedComponent('Fonts', BRANDED_COMPONENTS)
