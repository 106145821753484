import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as forms from '@rushplay/forms'
import * as common from '@rushplay/common'
import loadable from '@loadable/component'
import { useI18n } from '@rushplay/i18n'

import { Checkbox } from './checkbox'
import { FieldLabel } from './field-label'
import { getFieldNormalizer } from './get-field-normalizer'
import { getFieldParser } from './get-field-parser'

const FieldResponse = loadable(() => import('./field-response'))

export function CheckboxGroup(props) {
  const i18n = useI18n()
  const [options, setOptions] = React.useState([])
  const field = forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })

  function handleChange(key, value) {
    // checkbox was true
    if (value && R.not(R.includes(key, options))) {
      const nextOptions = R.append(key, options)
      setOptions(nextOptions)
      return field.onChangeValue(nextOptions)
    }
    // checkbox was false
    const nextOptions = R.without(key, options)
    setOptions(nextOptions)
    return field.onChangeValue(nextOptions)
  }

  return (
    <common.Box display="grid" gridGap={0}>
      <FieldLabel disabled={props.disabled}>
        {i18n.translate(field.label)}
      </FieldLabel>
      {R.map(
        item => (
          <Checkbox
            key={item.value}
            disabled={R.includes(item.key, props.disabled)}
            checked={R.includes(item.value, options)}
            value={R.includes(item.value, options)}
            onChange={value => handleChange(item.value, value)}
            label={i18n.translate(item.label)}
          />
        ),
        props.options
      )}
      <FieldResponse scope={props.scope} />
    </common.Box>
  )
}
CheckboxGroup.propTypes = {
  disabled: PropTypes.array,
  label: PropTypes.string,
  format: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  normalize: PropTypes.func,
  options: PropTypes.array,
  parse: PropTypes.func,
  scope: PropTypes.string,
}
