import * as R from 'ramda'
import Cookie from 'cookie'
import url from 'url'

import { getLanguageByCountryCode } from '@rushplay/common'

/**
 * Returns language if given complex locale tag
 * @param {string} IETF language tag (e.g. en-US, de-DE-u-co-phonebk)
 * @return {string} Language portion
 */
function getLanguageFromLocale(locale) {
  return R.head(R.split('-', locale))
}

function getLanguageFromCookies(req) {
  const cookies = Cookie.parse(R.defaultTo('', req.headers['cookie']))
  return cookies.language
}

function getAcceptLanguages(req) {
  const acceptLanguage = req.headers['accept-language'] || ''
  return acceptLanguage.replace(/;q=[0-9.]+/gi, '').split(',')
}

function getLanguageFromUrl(req) {
  const { pathname } = url.parse(req.url)
  const language = R.head(R.split('/', R.drop(1, pathname)))
  return language
}

function getLanguageFromCountryCode(req) {
  const countryCode = req.headers['cf-ipcountry'] || ''
  return getLanguageByCountryCode(countryCode)
}

export const toPreferredLanguages = R.unless(
  R.isNil,
  R.pipe(
    R.juxt([
      getLanguageFromUrl,
      getLanguageFromCookies,
      getAcceptLanguages,
      getLanguageFromCountryCode,
    ]),
    R.flatten,
    R.filter(R.is(String)),
    R.map(getLanguageFromLocale),
    R.filter(R.test(/^[a-z]{2}$/i)),
    R.uniq
  )
)
