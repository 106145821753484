import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import React from 'react'

import * as Jurisdiction from '@rushplay/compliance/jurisdiction'

import * as Constants from './constants'
import * as ServerConfiguration from './server-configuration'

export function useCookieConsent() {
  // TODO: Have backend return cookie consent in configuration as under 'features'
  const licenseId = ReactRedux.useSelector(state =>
    Jurisdiction.getLicense(state.jurisdiction)
  )
  const { brand } = ServerConfiguration.useContext()

  return React.useMemo(
    () =>
      R.includes(licenseId, [
        Constants.GamblingLicenseIds.MGA,
        Constants.GamblingLicenseIds.SGA,
        Constants.GamblingLicenseIds.IOM,
        Constants.GamblingLicenseIds.CURACAO,
        Constants.GamblingLicenseIds.COSTARICA,
      ]) && brand !== Constants.Brands.CASINOSKYJP,
    [licenseId, brand]
  )
}
