import * as React from 'react'

/* @jsx UiSchema.createElement */
import { UiSchema } from '@rushplay/forms'

export const consentSchema = (
  <UiSchema.Box>
    <UiSchema.Box>
      <UiSchema.Control scope="#/properties/consent" />
    </UiSchema.Box>
    <UiSchema.Box>
      <UiSchema.Submit />
    </UiSchema.Box>
  </UiSchema.Box>
)
