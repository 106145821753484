import PropTypes from 'prop-types'

/**
 * Converts a language code to a corresponding country code.
 *
 * @param {string} language - The language code to convert.
 * @returns {string} The corresponding country code.
 */
export function langToCountry(language) {
  // Workaround to render the British flag
  if (language === 'en') {
    return 'GB'
  }

  return language.toUpperCase()
}

// PropTypes for the function
langToCountry.propTypes = {
  language: PropTypes.string.isRequired,
}
