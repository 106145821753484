import * as R from 'ramda'
import * as React from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import * as common from '@rushplay/common'
import * as notifications from '@rushplay/notifications'
import { useI18n } from '@rushplay/i18n'

import { HtmlContent } from './html-content'
import { Toast } from './toast'

export function NotificationsContainer() {
  const i18n = useI18n()

  return (
    <common.Box
      position="fixed"
      top={['48px', '0px']}
      right="0px"
      width={['100%', 'auto']}
      minWidth={[null, '350px']}
      maxWidth="420px"
      zIndex="99999999999"
    >
      <notifications.Notifications mountPoint="notifications">
        {notificationIds => (
          <AnimatePresence>
            {R.map(
              notificationId => (
                <motion.div
                  positionTransition
                  key={notificationId}
                  initial={{ x: 100 }}
                  animate={{ x: 0 }}
                  exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
                  transition={{
                    type: 'spring',
                    stiffness: 300,
                    damping: 15,
                    duration: 0.25,
                  }}
                >
                  <notifications.Notification
                    id={notificationId}
                    mountPoint="notifications"
                  >
                    {notification => (
                      <common.Space pt={1} px={1}>
                        <Toast
                          variant={notification.level}
                          title={
                            notification.level
                              ? i18n.translate(`toast.${notification.level}`)
                              : 'Title'
                          }
                          onDismiss={() =>
                            notification.onDismiss(notificationId)
                          }
                        >
                          <HtmlContent
                            html={{
                              __html: notification.contentTranslated
                                ? notification.message
                                : notification.message
                                ? i18n.translate(
                                    notification.message,
                                    notification.variables
                                  )
                                : 'Content',
                            }}
                          />
                        </Toast>
                      </common.Space>
                    )}
                  </notifications.Notification>
                </motion.div>
              ),
              notificationIds
            )}
          </AnimatePresence>
        )}
      </notifications.Notifications>
    </common.Box>
  )
}
