import * as R from 'ramda'
import * as reselect from 'reselect'
import * as ReactRedux from 'react-redux'
import { bind } from 'redux-effects'

import * as Analytics from '@rushplay/analytics'
import * as Processes from '@rushplay/processes'
import * as Api from '@rushplay/api-client'
import * as Notifications from '@rushplay/notifications'
import { SESSION_EXPIRED } from '@rushplay/websockets'
import { session, types } from '@rushplay/session'

import * as Constants from './constants'
import * as Cookies from './cookies'
import * as Player from './player'

export const SESSION_UPDATED = types.UPDATE_SESSION

const INITIAL_STATE = {
  createdAt: null,
  token: null,
}

export function handleSuccess(response, options) {
  const hasZeroBalance =
    R.path(['player', 'account', 'balanceCents'], response.value) === 0
  const enforceProfileRedirect = R.pathOr(
    false,
    ['enforceProfileRedirect'],
    options
  )

  const isPhoneVerificationRequired =
    R.path(['player', 'requiresPhoneValidation'], response.value) ===
    'restricted'

  const player = response.value.player

  return [
    Player.playerInfoInit(
      R.mergeAll([
        R.path(['account'], player),
        R.path(['address'], player),
        player,
      ])
    ),
    Cookies.set(
      Constants.CookieKeys.IS_PHONE_VERIFICATION_REQUIRED,
      isPhoneVerificationRequired,
      {
        maxAge: 180 * 24 * 60 * 60,
        httpOnly: false,
        path: '/',
      }
    ),
    Analytics.authenticate(),
    Processes.stop('LOGIN_LOADING'),
    update(response.value.token, response.value.loggedInAt),
    hasZeroBalance &&
      !enforceProfileRedirect &&
      Player.updateEnforceDeposit(true),
    enforceProfileRedirect && Player.updateEnforceProfileRedirect(true),
    isPhoneVerificationRequired &&
      Api.requestSmsVerification({
        failure: () =>
          Notifications.add({
            message: 'errors.phone-verification-request.failed',
            level: 'error',
          }),
        version: 1,
        token: response.value.token,
      }),
  ]
}

export function handleFailure(error) {
  const errors = R.pathOr({}, ['value', 'errors', 'base'], error)
  const selfExclusion = R.find(R.pathEq(['reason'], 'self_exclusion'), errors)
  const timeout = R.find(R.pathEq(['reason'], 'timeout'), errors)
  const invalidCredentials = R.find(
    R.pathEq(['error'], 'Invalid Credentials'),
    errors
  )
  const zipRestriction = R.find(
    R.pathEq(['errorCode'], 'zip_restricted'),
    errors
  )
  const isLocked = errors.find(err => err.errorCode === 'locked')

  if (isLocked) {
    return [
      Processes.stop('LOGIN_LOADING'),
      Notifications.add({
        message: 'errors.login.seon-lock',
        level: 'error',
      }),
    ]
  }

  if (invalidCredentials) {
    return [
      Processes.stop('LOGIN_LOADING'),
      Notifications.add({
        message: 'errors.login.invalid-credentials',
        level: 'error',
      }),
    ]
  }

  if (selfExclusion) {
    return [
      Processes.stop('LOGIN_LOADING'),
      Notifications.add({
        message: 'errors.login.self-exclusion-lock',
        level: 'info',
        variables: {
          expiresAt: new Date(selfExclusion.expiresAt),
        },
      }),
    ]
  }

  if (timeout) {
    return [
      Processes.stop('LOGIN_LOADING'),
      Notifications.add({
        message: 'errors.login.timeout-lock',
        level: 'info',
        variables: {
          expiresAt: new Date(timeout.expiresAt),
        },
      }),
    ]
  }

  if (zipRestriction) {
    return [
      Processes.stop('LOGIN_LOADING'),
      Notifications.add({
        message: 'errors.login.zip-restricted',
        level: 'info',
      }),
    ]
  }

  return [
    Processes.stop('LOGIN_LOADING'),
    Notifications.add({
      message: `errors.${error.status || 'general.unknown'}`,
      level: 'error',
    }),
  ]
}

export function update(token = null, createdAt = null) {
  return bind(
    Cookies.set('token', token, {
      // When this cookie expires and is removed by a browser, it forces
      // GraphQL client to reinitialise. As a result it reinitialises entire
      // application and causes issues like gameplay interruption. As a
      // workaround the `max-age` of the cookie is set to be longer than any of
      // player sessions instead of previous value of 1 hour.
      maxAge: 24 * 60 * 60 * 1000,
      httpOnly: false,
      path: '/',
    }),
    () => ({
      type: SESSION_UPDATED,
      payload: {
        createdAt,
        token,
      },
    })
  )
}

export function login(username, password, clientType, options = {}) {
  return Api.login(username, password, clientType, {
    success: response => handleSuccess(response, options),
    failure: error => handleFailure(error),
    version: 2,
  })
}

export function logout() {
  return Api.logout({
    success: () => [
      Cookies.remove(Constants.CookieKeys.IS_PHONE_VERIFICATION_REQUIRED),
      { type: SESSION_EXPIRED },
      update(),
    ],
    version: 1,
  })
}

export function fetch(token) {
  return Api.fetchSession({
    token,
    success: response =>
      update(response.value.token, response.value.loggedInAt),
    failure: () => update(),
    version: 1,
  })
}

export function invalidate() {
  return [
    update(),
    bind(
      // Hey Vsauce, Leo here. What is so special about getting an Oreo cookie
      // in Boom’s codebase? What if I say the cookie in question does not even
      // matter?
      //
      // When we destroy session and dispatch a notification synchronously,
      // Framer Motion is not aware entire DOM have just gone to hell as we
      // reload app’s configuration on session change. What happens? It acts
      // naughty by throwing errors about unreachable DOM nodes it wants to
      // animate.
      //
      // What do we do then? We dispatch notification on next tick giving
      // Framer Motion just a bit more time to realise that when DOM packed
      // suitcase and said it just goes to buy some cigarettes... you know.
      //
      // It ain’t coming back, son. Move on.
      Cookies.get('OREO'),
      () => Notifications.add({ message: 'session-expired', level: 'info' })
    ),
  ]
}

/**
 * This action checks if the session is still alive or not.
 * If it's dead we update state.
 * on success we do nothing, as updating would trigger uneccesary re-renders and re-fetches.
 */
export function keepAlive(token) {
  return Api.fetchSession({
    token,
    failure: invalidate,
    version: 2,
  })
}

export function saveLanguagePreference(language) {
  return Api.saveLanguagePreference(language, {
    success: res => {
      return session.update({ player: res.value })
    },
    version: 1,
  })
}

/**
 * Extended `@rushplay/session` reducer
 * @param {SessionState} state
 * @param {FSA} action
 */
export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SESSION_EXPIRED:
    case SESSION_UPDATED: {
      const createdAt = R.path(['payload', 'createdAt'], action)
      const token = R.path(['payload', 'token'], action)

      if (token) {
        return { createdAt, token }
      }

      return INITIAL_STATE
    }

    default: {
      return state
    }
  }
}

export function getToken(state) {
  return R.pathOr(null, ['token'], state)
}

export function getCreatedAt(state) {
  return R.pathOr(null, ['createdAt'], state)
}

export const isAuthenticated = reselect.createSelector([getToken], token =>
  Boolean(token)
)

export function useAuthenticated() {
  const authenticated = ReactRedux.useSelector(state =>
    isAuthenticated(state.session)
  )
  return authenticated
}
