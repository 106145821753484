import PropTypes from 'prop-types'

import css from '@styled-system/css'
import styled from '@emotion/styled'

export const FieldLabel = styled.label`
  ${props =>
    css({
      fontSize: 1,
      fontFamily: 'body',
      fontWeight: 'bold',
      py: '0.35em',
      color: props.disabled ? 'input' : 'currentColor',
    })}
  display: block;
`

FieldLabel.propTypes = {
  disabled: PropTypes.bool,
}
