import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

import { Button } from '../button'

const DegbugTranslationsContext = React.createContext(null)

export function Provider(props) {
  const [debug, setDebugValue] = React.useState(false)

  return (
    <DegbugTranslationsContext.Provider value={{ debug, setDebugValue }}>
      {props.children}
    </DegbugTranslationsContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.element,
}

export function useContext() {
  return React.useContext(DegbugTranslationsContext)
}

export function DegbugTranslations() {
  const { debug, setDebugValue } = useContext()

  return (
    <>
      <Common.Box textAlign="center" mt="3">
        <Button onClick={() => setDebugValue(prev => !prev)}>
          {debug ? 'Hide Translation Keys' : 'Show Translation Keys'}
        </Button>
      </Common.Box>
    </>
  )
}
