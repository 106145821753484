import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as forms from '@rushplay/forms'
import { useI18n } from '@rushplay/i18n'

import { Button } from './button'

export function SubmitButton(props) {
  const i18n = useI18n()
  const form = forms.useFormContext()
  const errors =
    props.onlyScopes == null
      ? forms.getFormErrors(form.state)
      : R.pick(props.onlyScopes, forms.getFormErrors(form.state))

  return (
    <Button
      data-testid={`${form.name}-submit`}
      disabled={
        !R.isNil(props.disabled)
          ? props.disabled
          : R.not(R.isEmpty(errors)) || props.loading
      }
      fontSize={props.fontSize}
      stretch={props.stretch}
      type="submit"
      variant={props.variant}
    >
      {props.children
        ? props.children
        : props.label || i18n.translate(`${form.name}.submit.label`)}
    </Button>
  )
}

SubmitButton.defaultProps = {
  fontSize: [2, 3],
  variant: 'primary',
}

SubmitButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  label: PropTypes.string,
  loading: PropTypes.bool,
  stretch: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'primary-outlined',
    'secondary-outlined',
  ]),
  onlyScopes: PropTypes.arrayOf(PropTypes.string),
}
