import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as forms from '@rushplay/forms'
import * as common from '@rushplay/common'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { useI18n } from '@rushplay/i18n'

const Wrapper = styled('span', {
  shouldForwardProp: common.noneOf(['visible', 'valid', 'empty']),
})`
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  word-break: break-word;
  padding-top: 4px;
  ${props =>
    props.valid &&
    `color: ${t.color('success')(props)};
  `};
  ${props =>
    props.valid &&
    props.empty &&
    `color: ${t.color('gray')(props)};
  `};
  color: ${t.color('danger')};
`

export function FieldResponse(props) {
  const i18n = useI18n()
  const field = forms.useField(props.scope, { noRegister: true })

  const visited = field.status !== forms.FieldStatus.PRISTINE

  const errors = R.filter(
    error => i18n.hasTranslation(error.translationKey),
    field.errors
  )

  const empty = field.value === ''
  const valid = R.isEmpty(errors)
  const visuallyValid = !visited || valid
  const visible = !visuallyValid || (!valid && visited)

  if (!visible || valid) {
    return null
  }

  return (
    <Wrapper empty={empty} valid={valid}>
      <common.Box
        display="grid"
        gridGap="4px"
        data-testid={`${field.name}.field-response`}
      >
        {R.map(
          error => (
            <common.Text fontSize={1} key={error.translationKey}>
              {i18n.translate(error.translationKey, error.translationVariables)}
            </common.Text>
          ),
          errors
        )}
      </common.Box>
    </Wrapper>
  )
}

FieldResponse.propTypes = {
  scope: PropTypes.string.isRequired,
}

export default FieldResponse
