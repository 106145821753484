import * as R from 'ramda'
import React from 'react'

export function Nothing() {
  return null
}

/**
 * React Component
 * @typedef {Function} ReactComponent
 */

/**
 * React component mappings
 * @typedef {Object.<string, ReactComponent>} ReactComponentsMappings
 */

/**
 * Conditionally render a component if the 'prop' match
 * a property name defined in 'components'.
 * @param {string} prop Property name to match against the components mappings
 * @param {ReactComponentsMappings} components Component mappings; mappings
 *    of the properties and their values that you want to match against
 * @returns {ReactComponent}
 */
export function createSwitchableComponent(prop, components) {
  return function Switchable(props) {
    const Component = R.propOr(Nothing, props[prop], components)

    return <Component {...props} />
  }
}
